




import {Component} from 'vue-property-decorator';
import BaseNavigationPage from '@/components/BaseNavigationPage.vue';
import {mixins} from 'vue-class-component';
import Navigation from '@/mixins/Navigation';
import {archiveStore} from '@/store/modules/archive/ArchiveStore';

Component.registerHooks(['beforeRouteEnter'])

@Component({
  name: 'profile',
  components: {BaseNavigationPage}
})
export default class Profile extends mixins(Navigation) {

  get navItems() {
    const name = this.$route.name
    return [
      {
        title: 'Overview',
        type: 'profile-overview',
        name: 'profile-overview',
        query: {tab: 'General'},
        selected: name === 'profile-overview'
      },
      {
        title: 'Archive',
        type: 'profile-archive',
        name: 'profile-archive',
        query: {type: 'archive', subtype: 'personal'},
        counter: archiveStore.archiveCountPersonal,
        selected: name === 'profile-archive'
      },
      {
        title: 'Privacy and Security',
        type: 'profile-security',
        name: 'profile-security',
        query: {},
        selected: name === 'profile-security'
      },
      {
        title: 'Blocklist',
        type: 'block-list',
        name: 'block-list',
        query: {},
        selected: (name === 'block-list' || name === 'blocked-customer-profile')
      },
      {
        title: 'Help',
        type: 'profile-help',
        name: 'profile-help',
        query: {},
        selected: name === 'profile-help'
      }
    ];
  }

  public async onNavItemSelected(navItem) {
    if (navItem.name === 'profile-archive') {
      const archiveId = await archiveStore.findArchiveIdForType({
        type: navItem.query.type,
        subtype: navItem.query.subtype
      })
      if (archiveId) {
        await this.$router.push({name: 'profile-archive', params: {archiveId}, query: navItem.query});
        return
      }
    }
    await this.$router.push({name: navItem.name, query: navItem.querys});
  }

  public created() {
    this.selectPage(11);
  }
}
